<template>
  <surround-box
    specialColor="#082f3e"
    background="linear-gradient(270deg, rgba(21, 101, 182, 0) 0%, #3B7AC2 50%, rgba(21, 101, 182, 0) 95%)"
    title="生猪年出栏量"
  >
    <div class="chart-box">
      <div ref="chart" class="chart"></div>
      <div class="unit">单位：{{ unit }}</div>
      <!-- <div class="animate-row">
        <div
          v-for="i in 6"
          :key="i"
          v-show="currentLight > i"
          :class="['dot-span']"
        >
          <span class="left-corner"></span>
          <span class="left-corner"></span>
          <span class="left-corner"></span>
        </div>
      </div> -->
    </div>
  </surround-box>
</template>
<script>
import surroundBox from "@/components/mapComp/surroundBox2.vue";
import * as echarts from "echarts";

export default {
  components: {
    surroundBox,
  },
  data() {
    return {
      data: [],
      unit: "",
      currentLight: 1,
    };
  },
  methods: {
    //   初始化
    initChart() {
      let chart = echarts.init(this.$refs.chart);
      let options = {
        tooltip: {
          trigger: "axis",
        },
        grid: {
          containLabel: true,
          top: 48,
          left: 16,
          right: 32,
          bottom: 16,
        },
        xAxis: {
          data: this.data.map((item) => item.dimensionName),
          axisLabel: {
            color: "#fff",
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: ["#fff"],
              type: "dashed",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ["rgba(255,255,255,0.5)"],
              type: "dashed",
            },
          },
        },
        yAxis: {
          axisLabel: {
            color: "#fff",
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              type: "dashed",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ["rgba(255,255,255,0.5)"],
              type: "dashed",
            },
          },
        },
        series: [
          {
            type: "bar",
            data: this.data.map((item) => item.content),
            barWidth: 30,
            label: {
              show: true,
              position: "top",
              color: "#fff",
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#947DFF",
                },
                {
                  offset: 1,
                  color: "#23A6F2",
                },
              ]),
            },
          },
        ],
      };
      chart.setOption(options);
    },
    getStatics() {
      this.$post(this.$api.STATICS_MANAGE.GET2, {
        resourceTypeId: 12,
      }).then((res) => {
        this.data = res;
        this.initChart();
        this.unit = res[0].contentUnit;
      });
    },
    setAnimate() {
      let interval = setInterval(() => {
        if (this.currentLight === 7) this.currentLight = 1;
        else this.currentLight += 1;
      }, 1 * 1000);
      this.$once("hook:beforeDestroy", () => {
        clearInterval(interval);
      });
    },
  },
  mounted() {
    this.getStatics();
    // this.setAnimate();
  },
};
</script>
<style lang='less' scoped>
.chart-box {
  width: 100%;
  height: 230px;
  position: relative;
  .chart {
    width: 100%;
    height: 200px;
  }
  .unit {
    position: absolute;
    left: 16px;
    top: 12px;
    color: #fff;
    font-weight: bold;
  }
  .animate-row {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: 300px;
    height: 16px;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    background: #091311;
    padding: 4px;
    &::before {
      content: "";
      display: block;
      border-bottom: 16px solid #091311;
      border-left: 8px solid rgba(0, 0, 0, 0);
      position: absolute;
      left: -8px;
      bottom: 0;
    }
    &::after {
      content: "";
      display: block;
      border-top: 16px solid #091311;
      border-right: 8px solid rgba(0, 0, 0, 0);
      position: absolute;
      right: -8px;
      top: 0;
    }
    // .light-span {
    //   background: #24baba;

    //   &::before {
    //     border-bottom-color: #24baba;
    //   }
    //   &::after {
    //     border-top-color: #24baba;
    //   }
    // }
    // .dark-span{
    //   background: #24baba;

    //   &::before {
    //     border-bottom-color: #24baba;
    //   }
    //   &::after {
    //     border-top-color: #24baba;
    //   }
    // }
    .dot-span {
      background: #24baba;
      flex-grow: 0;
      flex-shrink: 0;
      width: 42px;
      height: 100%;
      position: relative;
      margin-left: 8px;
      &:first-child {
        margin-left: 0px;
      }
      &::before {
        content: "";
        display: block;
        border-bottom-width: 8px;
        border-bottom-style: solid;
        border-bottom-color: #24baba;
        border-left: 4px solid rgba(0, 0, 0, 0);
        position: absolute;
        left: -4px;
        bottom: 0;
      }
      &::after {
        content: "";
        display: block;
        border-top-width: 8px;
        border-top-style: solid;
        border-top-color: #24baba;
        border-right: 4px solid rgba(0, 0, 0, 0);
        position: absolute;
        right: -4px;
        bottom: 0;
      }
    }
  }
}
</style>